import React from "react";

import axios from "axios";
import { useEffect, useState } from "react";

import apiEndpointList from "../../../config/modules/timeline/endpoint";

import Loader from "../../UI/Loader/Loader";
import HourTabContent from "./HourTabContent";
import HourTabHeader from "./HourTabHeader";
import Dropdown from "../DayTab/Dropdown/Dropdown";
import { moduleTabOrder } from "../../utilities/modulesTabOrder";

import "./HourTab.scss";
import StatusCard from "../../UI/StatusCard/StatusCard";

function HourTab() {
  const [tabIndex, setTabIndex] = useState(1);
  const [hourApiData, setHourApiData] = useState("");
  const [loading, setLoading] = useState("IDLE");

  const switchTabHandler = (index) => {
    setTabIndex(index);
  };

  // GET CALL FOR STATUS BY HOUR
  function fetchStatusByHour() {
    axios
      .post(
        apiEndpointList.FETCH_BY_HOUR.baseUrl +
          apiEndpointList.FETCH_BY_HOUR.endpoint
      )
      .then((response) => {
        setHourApiData(response.data);
        setLoading("SUCCESS");
      })
      .catch((error) => {
        setLoading("ERROR");
      });
  }

  useEffect(() => {
    fetchStatusByHour();
  }, []);

  // Module sort function
  const sortModulesTab = (moduleOrderItems, hourApiItems) => {
    hourApiItems
      .sort((a, b) => {
        const aKey = Object.values(a)[1];
        const bKey = Object.values(b)[1];
        return moduleOrderItems.indexOf(aKey) - moduleOrderItems.indexOf(bKey);
      })
      .reverse();
  };

  if (hourApiData.length > 0) {
    sortModulesTab(moduleTabOrder, hourApiData);
  }

  // Renders Card on IDLE state
  if (loading === "IDLE") {
    return (
      <StatusCard>
        <Loader />
      </StatusCard>
    );
  }

  // Renders Card on ERROR state
  if (loading === "ERROR") {
    return (
      <StatusCard>
        <img
          className="error__icon"
          src="/images/error-icon.svg"
          alt="error-icon"
        />
        <p className="status-text">
          Oops, we couldn't catch that API hit <br />
          Please try again in sometime.
        </p>
      </StatusCard>
    );
  }

  return (
    <div data-testid="hour-tab" className="module-tab-container">
      {hourApiData.length > 0 && (
        <Dropdown
          ApiData={hourApiData}
          tabIndex={tabIndex}
          switchTabHandler={switchTabHandler}
        />
      )}

      {hourApiData.length > 0 ? (
        <>
          <HourTabHeader
            hourApiData={hourApiData}
            tabIndex={tabIndex}
            switchTabHandler={switchTabHandler}
          />
          <HourTabContent hourApiData={hourApiData} tabIndex={tabIndex} />
        </>
      ) : (
        <p className="no-detail-text">No information</p>
      )}
    </div>
  );
}

export default HourTab;
