import React from "react";
import "./Header.scss";

function Header() {
  return (
    <div>
      <div className="header">
        <a
          href="https://decentro.tech/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="header-logo"
            src="/images/decentro-black-logo.svg"
            alt="decentro black logo"
          />
        </a>
      </div>
    </div>
  );
}

export default Header;
