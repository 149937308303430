import React from "react";

import { v4 as uuidv4 } from "uuid";

import StatusBar from "../../UI/StatusBar/StatusBar";

const WeekContentRow = ({ item }) => {
  const getWeekData = (data) => {
    var date = new Date();
    date.setDate(date.getDate() - 0);

    // REFORMATING DATE T0 YYYY-MM-DD
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }

    // MAPPING DATA BASED ON WEEK STATUS BARS : COUNT 7
    let weekData = [
      ...Array(7)
        .fill(0)
        .map((x, i) => ({
          Timeslot: i,
          status: "disabled",
          tab: "week",
          date: formatDate(new Date().setDate(new Date().getDate() - i)),
        })),
    ];

    // MAPPING BARS BASED ON WEEK STATUS BARS
    let weekResult = weekData.map(function (x) {
      var result = data.filter((a1) => a1.Timeslot === x.date);

      return result;
    });

    // REASSIGNING EMPTY ARRAY TO WEEK_DATA FOR STATUS_BAR
    for (var i = 0; i < weekResult.length; i++) {
      if (weekResult[i].length === 0) {
        weekResult.splice(i, 1, [..."", weekData[i]]);
      }
      continue;
    }

    // REORDERING BY WEEK SLOT
    return weekResult.reverse();
  };

  return (
    <React.Fragment>
      {item.modDetails.map((dataItem, index) => {
        return (
          <div key={uuidv4()} className="week-content-container">
            <div key={uuidv4()} className="content__header">
              <p>{dataItem.title}</p>
            </div>

            <div className="progress__bar">
              {getWeekData(dataItem.data).map((dataItem, index) => {
                return (
                  <StatusBar
                    key={uuidv4()}
                    data={dataItem[0] ? dataItem[0] : { status: "disabled" }}
                    timeSlot="week"
                  />
                );
              })}
            </div>

            <div data-testid="week-tab-row" className="timeline__text">
              <span data-testid="past-time-text">6 days ago</span>
              <span data-testid="current-time-text">Now</span>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default WeekContentRow;
