import { useState } from "react";
import "./Dropdown.scss";
import { v4 as uuidv4 } from "uuid";

function Dropdown({ ApiData, tabIndex, switchTabHandler }) {
  const [selected, setSelected] = useState(ApiData[0].moduleName);
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="dropdown">
      <div className="dropdown-btn" onClick={(e) => setIsActive(!isActive)}>
        {selected}
        {ApiData.length !== 1 && (
          <span>
            <DownArrow isActive={isActive} />
          </span>
        )}
      </div>
      {isActive && (
        <div className={`${ApiData.length !== 1 && "dropdown-content"}`}>
          {ApiData.length > 0
            ? ApiData.map((tabTitle, i) => (
                <div key={uuidv4()}>
                  {tabTitle.moduleName !== selected && (
                    <div
                      key={uuidv4()}
                      onClick={(e) => {
                        setSelected(tabTitle.moduleName);
                        setIsActive(false);
                        switchTabHandler(i + 1);
                      }}
                      className="dropdown-item"
                    >
                      {tabTitle.moduleName}
                    </div>
                  )}
                </div>
              ))
            : ""}
        </div>
      )}
      <div className="dropdown-divider">
        <span></span>
      </div>
    </div>
  );
}

function DownArrow({ isActive }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-6 w-6 dropdown-arrow ${
        isActive && "dropdown-arrow__rotate"
      }`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export default Dropdown;
