let apiEndpointList = {
  FETCH_BY_HOUR: {
    endpoint: "/decentro/open/fetch/status_by_hour",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_BY_DAY: {
    endpoint: "/decentro/open/fetch/status_by_day",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_BY_WEEK: {
    endpoint: "/decentro/open/fetch/status_by_week",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
};

export default apiEndpointList;
