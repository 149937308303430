import React from "react";

import "./HourStatusChip.scss";

const HourStatusChip = ({ data, chipStatusClass }) => {
  return (
    <div className="status-chip-container">
      <div className={`${chipStatusClass} hour-chip`}>
        <span className="chip-span chip__text">
          {data.STATUS === "Experiencing low performance"
            ? "Low Performance"
            : data.STATUS}
        </span>
      </div>
    </div>
  );
};

export default HourStatusChip;
