import React from "react";

import apiEndpointList from "../../../config/modules/timeline/endpoint";

import axios from "axios";
import { useEffect, useState } from "react";

import Loader from "../../UI/Loader/Loader";
import WeekTabContent from "./WeekTabContent";
import WeekTabHeader from "./WeekTabHeader";
import Dropdown from "../DayTab/Dropdown/Dropdown";
import StatusCard from "../../UI/StatusCard/StatusCard";
import { moduleTabOrder } from "../../utilities/modulesTabOrder";

import "./WeekTab.scss";

function WeekTab() {
  const [tabIndex, setTabIndex] = useState(1);
  const [weekApiData, setWeekApiData] = useState("");
  const [loading, setLoading] = useState("IDLE");

  const switchTabHandler = (index) => {
    setTabIndex(index);
  };

  // GET CALL FOR STATUS BY WEEK
  function fetchStatusByWeek() {
    axios
      .post(
        apiEndpointList.FETCH_BY_WEEK.baseUrl +
          apiEndpointList.FETCH_BY_WEEK.endpoint
      )
      .then((response) => {
        setWeekApiData(response.data);
        setLoading("SUCCESS");
      })
      .catch((error) => {
        setLoading("ERROR");
      });
  }

  useEffect(() => {
    fetchStatusByWeek();
  }, []);

  // Module sort function
  const sortModulesTab = (moduleOrderItems, hourApiItems) => {
    hourApiItems
      .sort((a, b) => {
        const aKey = Object.values(a)[1];
        const bKey = Object.values(b)[1];
        return moduleOrderItems.indexOf(aKey) - moduleOrderItems.indexOf(bKey);
      })
      .reverse();
  };

  if (weekApiData.length > 0) {
    sortModulesTab(moduleTabOrder, weekApiData);
  }

  // Renders Card on IDLE state
  if (loading === "IDLE") {
    return (
      <StatusCard>
        <Loader />
      </StatusCard>
    );
  }

  // Renders Card on ERROR state
  if (loading === "ERROR") {
    return (
      <StatusCard>
        <img
          className="error__icon"
          src="/images/error-icon.svg"
          alt="error-icon"
        />
        <p className="status-text">
          Oops, we couldn't catch that API hit <br />
          Please try again in sometime.
        </p>
      </StatusCard>
    );
  }

  return (
    <div data-testid="week-tab" className="module-tab-container">
      {weekApiData.length > 0 && (
        <Dropdown
          ApiData={weekApiData}
          tabIndex={tabIndex}
          switchTabHandler={switchTabHandler}
        />
      )}

      {weekApiData.length > 0 ? (
        <>
          <WeekTabHeader
            weekApiData={weekApiData}
            tabIndex={tabIndex}
            switchTabHandler={switchTabHandler}
          />

          <WeekTabContent weekApiData={weekApiData} tabIndex={tabIndex} />
        </>
      ) : (
        <p className="no-detail-text">No information</p>
      )}
    </div>
  );
}

export default WeekTab;
