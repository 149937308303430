import React from "react";

const StatusBar = ({ data, timeSlot }) => {
  const yesterday = () => {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    return d.toISOString().split("T")[0].split("-").reverse().join("-");
  };

  const today = () => {
    let d = new Date();
    d.setDate(d.getDate());
    return d.toISOString().split("T")[0].split("-").reverse().join("-");
  };

  if (!!data && data.status === "disabled") {
    return (
      <div className="tooltip no-status chip">
        <span className="tooltip-text">
          <p>
            <strong>No API Hits</strong>
          </p>
          {!!data.Timeslot && data.tab === "day" && (
            <p>
              {+data.Timeslot} {+data.Timeslot === 1 ? "hour" : "hours"} ago
            </p>
          )}
          {!!data.date && data.tab === "week" && (
            <p>{data.date.split("-").reverse().join("-")}</p>
          )}
        </span>
      </div>
    );
  }

  let statusClass;

  if (
    +data.SUCCESS_RATIO.slice(0, -1) >= 98 &&
    +data.SUCCESS_RATIO.slice(0, -1) <= 100
  ) {
    statusClass = "success chip";
  } else if (
    +data.SUCCESS_RATIO.slice(0, -1) >= 95 &&
    +data.SUCCESS_RATIO.slice(0, -1) < 98
  ) {
    statusClass = "fluctuation chip";
  } else if (+data.SUCCESS_RATIO.slice(0, -1) < 95) {
    statusClass = "failure chip";
  }

  const currentHour = new Date().getHours();

  return (
    <div className={`tooltip ${statusClass}`}>
      <span className="tooltip-text">
        <p>
          <strong>{data.SUCCESS_RATIO}</strong>
        </p>
        {timeSlot === "week" && (
          <p>{data.Timeslot.split("-").reverse().join("-")}</p>
        )}
        {timeSlot === "day" && (
          <p>{data.Timeslot > currentHour ? yesterday() : today()}</p>
        )}
        {timeSlot === "day" && <p>{data.Timeslot} hours ago</p>}
      </span>
    </div>
  );
};

export default StatusBar;
