import React from "react";

import { v4 as uuidv4 } from "uuid";

import StatusBar from "../../UI/StatusBar/StatusBar";

const DayContentRow = ({ item }) => {
  // MAPPING DATA BASED ON HOUR STATUS BARS : COUNT 0 - 23
  const getDayData = (data) => {
    // SUPPORT CONTAINER
    let content = [
      ...Array(24)
        .fill(0)
        .map((x, slotIndex) => ({
          Timeslot: slotIndex,
          status: "disabled",
          tab: "day",
        })),
    ];

    // MAPPING BARS BASED ON HOUR STATUS BARS
    for (let i = 0; i < 23; i++) {
      if (data[i]) {
        content.splice(data[i].Timeslot, 1, data[i]);
      }
      continue;
    }

    // REORDERING BY TIME SLOT
    return content.sort((a, b) => {
      return b.Timeslot - a.Timeslot;
    });
  };

  return (
    <React.Fragment>
      {item.modDetails.map((dataItem, index) => {
        return (
          <div key={uuidv4()} className="day-content-container">
            <div key={uuidv4()} className="content__header">
              <p>{dataItem.title}</p>
            </div>

            <div className="progress__bars">
              {getDayData(dataItem.data).map((dataItem, index) => {
                return (
                  <StatusBar key={uuidv4()} data={dataItem} timeSlot="day" />
                );
              })}
            </div>

            <div data-testid="day-tab-row" className="timeline__text">
              <span data-testid="past-time-text">23 hours ago</span>
              <span data-testid="current-time-text">Now</span>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default DayContentRow;
