import React from "react";

import WeekContentRow from "./WeekContentRow";

import { v4 as uuidv4 } from "uuid";

const WeekTabContent = ({ weekApiData, tabIndex }) => {
  return (
    <div data-testid="week-tab-content" className="content-tab">
      {weekApiData.length > 0
        ? weekApiData.map((item, index) => {
            return (
              <div
                key={uuidv4()}
                className={
                  tabIndex === index + 1 ? "content  active-content" : "content"
                }
              >
                <WeekContentRow item={item} />
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default WeekTabContent;
