import React from "react";

import { v4 as uuidv4 } from "uuid";

import DayContentRow from "./DayContentRow";

const DayTabContent = ({ dayApiData, tabIndex }) => {
  return (
    <div data-testid="day-tab-content" className="content-tab">
      {dayApiData.length > 0
        ? dayApiData.map((item, index) => {
            return (
              <div
                key={uuidv4()}
                className={
                  tabIndex === index + 1 ? "content  active-content" : "content"
                }
              >
                <DayContentRow item={item} />
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default DayTabContent;
