import React from "react";

import { v4 as uuidv4 } from "uuid";

const HourTabHeader = ({ hourApiData, tabIndex, switchTabHandler }) => {
  return (
    <div data-testid="hour-tab-header" className="tab__block">
      {hourApiData.length > 0
        ? hourApiData.map((tabTitle, i) => {
            return (
              <div key={uuidv4()}>
                <button
                  className={tabIndex === i + 1 ? "tab active-tab" : "tab"}
                  onClick={() => switchTabHandler(i + 1)}
                >
                  {tabTitle.moduleName}
                </button>
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default HourTabHeader;
