import React from "react";
import "./LegendBar.scss";

const LegendBar = ({ className = "fluctuation", statusText="Fluctuation" }) => {
  let classes = `status__bar ${className}`;

  return (
    <div className="legend__bar">
      <span className={classes}></span>
      <p className="status__text">{statusText}</p>
    </div>
  );
};

export default LegendBar;
