import React from "react";
import { v4 as uuidv4 } from "uuid";

const WeekTabHeader = ({ weekApiData, tabIndex, switchTabHandler }) => {
  return (
    <div data-testid="week-tab-header" key={uuidv4()} className="tab__block">
      {weekApiData.length > 0 ? (
        weekApiData.map((tabTitle, i) => {
          return (
            <div key={uuidv4()}>
              <button
                className={tabIndex === i + 1 ? "tab active-tab" : "tab"}
                onClick={() => switchTabHandler(i + 1)}
              >
                {tabTitle.moduleName}
              </button>
            </div>
          );
        })
      ) : (
        <p>Loading..</p>
      )}
    </div>
  );
};

export default WeekTabHeader;
