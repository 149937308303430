import React from "react";
import Header from "./components/Header/Header";
import TimelinePanel from "./components/TimelinePanel/TimelinePanel";

function App() {
  const lightSchemeIcon = document.querySelector("link#light-scheme-icon");
  const darkSchemeIcon = document.querySelector("link#dark-scheme-icon");

  const isDark = window.matchMedia("(prefers-color-scheme: dark)");

  const changeFavicon = () => {
    if (isDark.matches) {
      document.head.append(darkSchemeIcon);
      lightSchemeIcon.remove();
    } else {
      darkSchemeIcon.remove();
      document.head.append(lightSchemeIcon);
    }
  };

  React.useEffect(() => {
    changeFavicon();
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      isDark.addListener(changeFavicon);
    } else {
      isDark.addEventListener("change", changeFavicon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Header />
      <TimelinePanel />
      {/* <div className="footer-bottom">Footer</div> */}
    </div>
  );
}

export default App;
