import React from "react";

import HourStatusChip from "../../UI/HourStatusChip/HourStatusChip";
import HourContentRow from "./HourContentRow";

import { v4 as uuidv4 } from "uuid";

const HourTabContent = ({ hourApiData, tabIndex }) => {
  const chipStatusHandler = (data) => {
    let chipStatusClass;
    if (
      +data.SUCCESS_RATIO.slice(0, -1) >= 98 &&
      +data.SUCCESS_RATIO.slice(0, -1) <= 100
    ) {
      chipStatusClass = "success__chip";
    } else if (
      +data.SUCCESS_RATIO.slice(0, -1) >= 95 &&
      +data.SUCCESS_RATIO.slice(0, -1) <= 98
    ) {
      chipStatusClass = "fluctuating__chip";
    } else if (+data.SUCCESS_RATIO.slice(0, -1) < 95) {
      chipStatusClass = "failure__chip";
    }
    return <HourStatusChip data={data} chipStatusClass={chipStatusClass} />;
  };

  return (
    <div data-testid="hour-tab-content" className="content-tab">
      {hourApiData.length > 0
        ? hourApiData.map((item, index) => {
            return (
              <div
                key={uuidv4()}
                className={
                  tabIndex === index + 1 ? "content  active-content" : "content"
                }
              >
                <HourContentRow
                  item={item}
                  chipStatusHandler={chipStatusHandler}
                />
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default HourTabContent;
