import React from "react";

import { v4 as uuidv4 } from "uuid";

const HourContentRow = ({ item, chipStatusHandler }) => {
  return (
    <React.Fragment>
      {item.modDetails.map((data, index) => {
        return (
          <div data-testid="hour-tab-row" className="hour-data" key={uuidv4()}>
            {data.data.map((data, index) => {
              return (
                <div key={uuidv4()} className="hour-content-container">
                  <div className="content__header">
                    <p className="content__title">{data.API}</p>
                    <p className="content__ratio">{data.SUCCESS_RATIO}</p>
                    {data.SUCCESS_RATIO && chipStatusHandler(data)}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default HourContentRow;
