import React, { useState } from "react";

import DayTab from "../ModulesTab/DayTab/DayTab";
import HourTab from "../ModulesTab/HourTab/HourTab";
import WeekTab from "../ModulesTab/WeekTab/WeekTab";
import LegendBar from "../UI/LegendBar/LegendBar";

import "./TimelinePanel.scss";

const TimelinePanel = () => {
  const [timelineTabIndex, setTimelineTabIndex] = useState(1);
  const [tabHeading, setTabHeading] = useState(" 1 hour");

  const switchTabHandler = (tab, tabName) => {
    setTimelineTabIndex(tab);
    setTabHeading(tabName);
  };

  let timeLineTabMapData = [
    { tabName: " 1 hour" },
    { tabName: " 1 day" },
    { tabName: " 1 week" },
  ];

  let timeLinePanelMapData = [<HourTab />, <DayTab />, <WeekTab />];

  let tabHeadingText = tabHeading === " 1 hour" ? "API Health" : "API Activity";

  return (
    <div className="timeline-panel">
      {/* ********************* PANEL - HEADER ********************* */}
      <div className="panel__container">
        <div className="panel__heading">
          <p data-testid="timeline-heading">{tabHeadingText}</p>
        </div>

        <div className="panel__container-right">
          {/* ********************* PANEL - STATUS - LEGEND ********************* */}
          <div className="legend-block">
            {/* V2://* FOR FUTURE USE */}
            {/* <LegendBar className="no-status__dark" statusText="No API Hits" /> */}
            <LegendBar className="success" statusText="Great Performance" />
            <LegendBar className="fluctuation" statusText="Fluctuating" />
            <LegendBar className="failure" statusText="Low Performance" />
          </div>

          {/* ********************* PANEL - TAB ********************* */}

          <div className="timeline-tab">
            {timeLineTabMapData.map((data, i) => (
              <button
                key={i}
                className={
                  timelineTabIndex === i + 1
                    ? "tab__inactive tab__active"
                    : "tab__inactive"
                }
                onClick={() => switchTabHandler(i + 1, data.tabName)}
              >
                {data.tabName}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* ********************* PANEL - TAB - MODULE - CONTENT ********************* */}

      <div className="timeline-panel__content">
        {timeLinePanelMapData.map((data, i) => (
          <div
            key={i}
            className={
              timelineTabIndex === i + 1
                ? "content-timeline-tab  active-content-timeline-tab"
                : "content-timeline-tab"
            }
          >
            {data}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimelinePanel;
